import '../../../css/App.css';
import React from 'react';
import Typical from 'react-typical';

function HelloCursor() {
    return(
        <React.Fragment>
         <h2 align="center" className="cursor" style={{fontSize: 60}}>
          <Typical steps={['Hi', 1000, 'Hi, I\'m Soumya', 1000]} loop={1} />
        </h2>

        <h3 align="center" style={{fontSize: 30}}>
          A Software Engineer based in London
          <br></br><br></br>
        </h3>
        </React.Fragment>
    );
}

export default HelloCursor;
import '../../../css/App.css';
import React from 'react';

function About() {
    return(
        <React.Fragment>
            <div className = "about" id = "about">
                <p style={{fontSize: 20}}>
                    I work at <a href="https://www.db.com/">Deutsche Bank</a> as a Software Engineer. 
                </p>
                <p style={{fontSize: 20}}>
                    I <a href="https://www.dunelm.org.uk/volunteer/alumni-profile---soumya-singh">studied Computer Science at Durham University</a> in the United Kingdom, where
                    I was also a teaching assistant for Year 1 Programming. <a href="https://www.dpskanpur.com/alumni/">I went to high school in India</a>, where I was the head girl. 
                </p>
                <p style={{fontSize: 20}}>
                    I often go by <b>singhuist</b> online. 
                </p>
            </div>
        </React.Fragment>
    );
}

export default About;
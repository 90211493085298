import React from 'react'
import Card from 'react-bootstrap/Card'
import {Row} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { faPodcast} from '@fortawesome/free-solid-svg-icons'

library.add(fab,faPodcast)

function Podcasts(){
    return(
    <React.Fragment>    
    <Row xs={1} md={2} className="g-4">    
    <div className="col-sm-6 d-flex justify-content-center" id="pcast">
    <Card style={{ width: '350px'}}>  
        <iframe style={{flexShrink: 0}} src="https://anchor.fm/chrisrobertsdubs/embed/episodes/Soumya-Singh-Spring-Weeks--internships-and-graduate-schemes--an-inside-look-at-a-career-in-Fintech-esksr4/a-a4utjrt" 
        height="100px" frameborder="0" scrolling="no"></iframe>
        <h2>The Lemonade Principle</h2>
        <p align="justify" style={{margin: 10}}>
                    Durham University's alumni podcast where I discuss my journey to the UK as an international student,
                    transitioning from university to work, the role of technology in fintech and my two cents for students!
                    Listen below!
                </p>
                <Card.Body>
                <a href="https://anchor.fm/chrisrobertsdubs/episodes/Soumya-Singh-Spring-Weeks--internships-and-graduate-schemes--an-inside-look-at-a-career-in-Fintech-esksr4">
                    <FontAwesomeIcon icon="podcast" size="3x" style={{color:"darkorchid"}} />
                </a> &nbsp;
                <a href="https://open.spotify.com/episode/42G4tx9SrHaKQRTRjZ2YJj?si=88006d21d10946ba">
                    <FontAwesomeIcon icon={['fab', 'spotify']} size="3x" style={{color:"#1DB954"}}/> 
                </a> &nbsp;
                <a href="https://podcasts.google.com/feed/aHR0cHM6Ly9hbmNob3IuZm0vcy8xNDIzZjY5MC9wb2RjYXN0L3Jzcw/episode/YmNhODgzYWMtNzYxNy00NjAwLWJmOTctNzRjMDA1ODYwZGNj?sa=X&ved=0CAUQkfYCahcKEwj45fO8rJSDAxUAAAAAHQAAAAAQAQ">
                    <FontAwesomeIcon icon={['fab', 'google']} size="3x" /> 
                </a> &nbsp;
                <a href="https://podcasts.apple.com/gb/podcast/soumya-singh-spring-weeks-internships-graduate-schemes/id1501555337?i=1000513891211">
                    <FontAwesomeIcon icon={['fab', 'apple']} size="3x" style={{color:"black"}}/>
                </a> &nbsp;
                </Card.Body>
            </Card>
    </div>

    <div className="col-sm-6 d-flex justify-content-center" id="pcast">
    <Card style={{ width: '350px' }}>
            <iframe style={{flexShrink: 0}} src="https://anchor.fm/fyccpodcast/embed/episodes/Working-in-Deutsche-Bank-with-Soumya-Singh-ef6k47/a-a2ecgdc" 
            height="100px" frameborder="0" scrolling="no"></iframe>
                <h2>Find Your Career Calling</h2>
                <p align="justify" style={{margin: 10}}>
                    This podcast, run by a friend, interviews young professionals across industries who talk about their first
                    professional experiences. I discuss my journey of starting my career in technology at a financial services
                    firm. Listen below!
                </p>
                <Card.Body>
                <a href="https://anchor.fm/fyccpodcast/episodes/Working-in-Deutsche-Bank-with-Soumya-Singh-ef6k47">
                    <FontAwesomeIcon icon="podcast" size="3x" style={{color:"darkorchid"}}/>
                </a> &nbsp;
                <a href="https://open.spotify.com/episode/776BTuVT8EgR4Q6cosoD1b?si=7FzCueBeQc26lk1LC1E8ww">
                    <FontAwesomeIcon icon={['fab', 'spotify']} size="3x" style={{color:"#1DB954"}}/> 
                </a> &nbsp;
                <a href="https://podcasts.google.com/feed/aHR0cHM6Ly9hbmNob3IuZm0vcy8zNDZlNDQwL3BvZGNhc3QvcnNz/episode/ZGQxNTdlYjUtY2M4Ni00MmQ4LWE3OTItYWMwZTUzMGQ2Njcw?sa=X&ved=0CAUQkfYCahcKEwig7uPpqLXvAhUAAAAAHQAAAAAQCA">
                    <FontAwesomeIcon icon={['fab', 'google']} size="3x" /> 
                </a> &nbsp;
                <a href="https://podcasts.apple.com/us/podcast/working-in-deutsche-bank-with-soumya-singh/id1373032069?i=1000477495871">
                    <FontAwesomeIcon icon={['fab', 'apple']} size="3x" style={{color:"black"}}/> 
                </a> &nbsp;
                </Card.Body>
            </Card>
    </div>
    </Row>
  
    </React.Fragment>
    );
}

export default Podcasts;
import React from 'react';
import '../../css/App.css';
import 'semantic-ui-css/semantic.min.css';
import duo from '../assets/img/gifs_duo-nobg.gif'
import ted from '../assets/img/ted.jpg'


function Languages(){
    return(
        <React.Fragment>
            <div className = "languages" id="languages">
                <div className="row">
                    <div className="col-sm-6">
                        <div className='row'>
                            <div className="col-sm-4">
                                    <img src = {duo} width="175px" height="170px"></img>
                            </div>
                            <div className="col-sm-8">
                                <h1 id="hobbiesHeader" style={{fontSize: 50, fontWeight: "bold"}}>Languages Fascinate Me<br></br></h1>
                            </div>
                            <div className="col-sm-12">
                                    <p style={{fontSize: 16}} id="duol">I love learning new ones and enjoy everything to do with them. My proudest accomplishment, however, is <b>creating a full-fledged language course for <a href="https://www.duolingo.com/">Duolingo - 
                                        the world's most popular language-learning app</a>.</b> I built 
                                        their <a href="https://www.duolingo.com/enroll/hi/en/Learn-Hindi">Hindi for English Speakers course</a>, 
                                        which currently has over <b>11 Million</b> learners globally, with two other people.</p>
                                        <br></br>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-6">
                        <div className='row'>
                            <div className="col-sm-6">
                                    <p id="ted" style={{fontSize: 16, marginTop: 10}}>I also <b><a href="https://www.ted.com/participate/translate">translate TED talks</a></b>! I attended <a href="https://conferences.ted.com/ted2024">TED 2024</a> - the flaghsip TED Conference - in Vancouver as one of the only ten translator delegates from the thousands across the world. As a group, we brainstormed how AI could change translation, amidst the experience of listening to some of the greatest ideas live from the original TED stage.</p>
                            </div>
                            <div className='col-sm-6'>
                                    <img src={ted} width={250} height={250} ></img>
                            </div>
                        </div>
                            <br></br>
                    </div>
                    
                </div>
            </div>
        </React.Fragment>
    );
}

export default Languages;
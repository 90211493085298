import React, {useState} from 'react';
import 'semantic-ui-css/semantic.min.css';
import emailjs from 'emailjs-com';
import '../../css/App.css';
import {SERVICE_ID, FORM_TEMPLATE_ID, USER_ID} from './constants'

const validateEmail = (email) => {
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailPattern.test(email);
};

const formFilled = (name, email, message) => {
  return (name.length > 0 && email.length > 0);
}

function ContactForm({onClose}){

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isError, setIsError] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();
    if (!formFilled(document.getElementById("sender_name").value, document.getElementById("sender_email").value, document.getElementById("msgarea").value)){
      alert("Please fill in all required fields.");
      return
    }
    
    if (!validateEmail(document.getElementById("sender_email").value)){
      alert("Please enter a valid email address.");
      return
    }
    emailjs.send(SERVICE_ID, FORM_TEMPLATE_ID, {
      from_name: document.getElementById("sender_name").value,
      message: document.getElementById("msgarea").value,
      reply_to: document.getElementById("sender_email").value
    }, USER_ID)
      .then((result) => {
        setIsSubmitted(true);
      }, 
      (error) => {
        setIsError(true);
      });
  };

  return (

    <div className="contact-form-overlay">
      <div className="contact-form">
        {
          //if the email was submitted successfully, show a success message
          isSubmitted ? (
            <>
              <p id="msg-ack">Thanks, your message has been sent!</p>
              <button onClick={onClose} className="form-close-button">Close</button>
            </>) 
          : (
              //if there was an error, show an error message
              isError ? (
                <>
                  <p id="msg-err">Sorry, there was an error sending your message. Please try again later.</p>
                  <button onClick={onClose} className="form-close-button">Close</button>
                </>)
              :(
                //if the form has not been submitted, show the form
              <>
                <form onSubmit={sendEmail} >
                  <label>NAME (required)</label>
                  <textarea id="sender_name" name="sender" cols="15" rows="1"></textarea>
                  <br></br>
                  <label>EMAIL (required)</label>
                  <textarea id="sender_email" name="email" cols="15" rows="1"></textarea>
                  <br></br>
                  <label>YOUR MESSAGE</label>
                  <textarea id="msgarea" name="message" cols="15" rows="8" />
                  <br></br><br></br>
                  <div className = "button-container">
                    <button type="submit" className="form-submit-button">Send</button>
                    &nbsp;&nbsp;&nbsp;
                    <button onClick={onClose} className="form-close-button">Close</button>
                  </div>
                </form>
                </>
            )

          )
      }
      </div>
    </div>
//function closing brackets - do not delete
);
}
    
  
export default ContactForm;
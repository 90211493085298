import React from 'react';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import '../../../css/App.css';
import ns from '../../assets/img/newscientist.png';
import hindu from '../../assets/img/hindu.png';
import thrive from '../../assets/img/thrive.png';
import sah from '../../assets/img/sah.jpg'

function Articles(){
    return(
        <React.Fragment>
        <br></br>
        <br></br>
        <p className="articleHeader" style={{fontSize: 20}}>I am quite fond of writing and have a thing or two featured on popular platforms</p>
        <br></br>
        <br></br>
        <br></br>
    <div className="row"> 
    <div className="col-md-3 d-flex justify-content-center">   
        <Card style={{ width: '18rem' }} className="card" >
            <Card.Img variant="top" src={ns} style={{width: '18rem', height: '12rem', flexGrow:100}} />
            <Card.Body>
            <Card.Title><b>A Mentor for the Next Generation</b></Card.Title>
            <Card.Text>
                My article about the impact of mentorship was published as a part of the 
                London Tech Week 2020 in New Scientist, a popular Science & Technology Magazine in the UK.
            </Card.Text>
            <a href="https://www.businessandindustry.co.uk/careers-in-tech/become-a-stem-sherpa-a-mentor-for-the-next-generation/"><Button>Read</Button></a>
            </Card.Body>
        </Card>
        <br></br>
   </div>
  
   <div className="col-md-3 d-flex justify-content-center">   
        <Card style={{ width: '18rem'}} className="card" >
            <Card.Img variant="top" src = {thrive} style={{width: '18rem', height: '12rem'}}/>
            <Card.Body>
            <Card.Title><b>Two faces of the Same Coin - Combating Burnout</b></Card.Title>
            <Card.Text>
                I co-authored an article about our battle with and triumph over burnout, which was featured
                in Thrive, a mental health and wellbeing focused media platform by Arianna Huffington. 
            </Card.Text>
            <a href="https://thriveglobal.com/stories/two-faces-of-the-same-coin-how-we-combated-burn-out/"><Button>Read</Button></a>
            </Card.Body>
        </Card>
        <br></br>
   </div>

   <div className="col-md-3 d-flex justify-content-center">   
        <Card style={{ width: '18rem' }} className="card" >
            <Card.Img variant="top" src={hindu} style={{width: '18rem', height: '12rem'}} />
            <Card.Body>
            <Card.Title><b>Computers and Harry Potter</b></Card.Title>
            <Card.Text>
                My experience of studying abroad was selected and published in The Hindu, India's leading
                English Language daily newspaper for their Education Plus series.
            </Card.Text>
            <a href="https://www.thehindu.com/todays-paper/tp-features/tp-educationplus/computers-and-harry-potter/article19749224.ece"><Button>Read</Button></a>
            </Card.Body>
        </Card>
        <br></br>
   </div>
  
   <div className="col-md-3 d-flex justify-content-center">   
        <Card style={{ width: '18rem' }} className="card" >
            <Card.Img variant="top" src={sah} style={{width: '18rem', height: '12rem'}} />
            <Card.Body>
            <Card.Title><b>Moving Abroad for Higher Education</b></Card.Title>
            <Card.Text>
                My learnings and tips for those moving countries to pursue education, published in Scientists Are Humans - 
                a movement to make science & academia a little more kind.
            </Card.Text>
            <a href="https://scientistsarehumans.wordpress.com/2019/01/29/moving-abroad-for-higher-education/"><Button>Read</Button></a>
            </Card.Body>
        </Card>
        <br></br>
   </div>
     
   </div>

    </React.Fragment>  
    );
}

export default Articles; 
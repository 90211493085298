import React from 'react';
import '../../css/App.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faHandHoldingHeart} from '@fortawesome/free-solid-svg-icons';

function Causes(){
    return(
        <React.Fragment>
        <div className="causes" id="causes">
            <div className='row'>
            <div className='col-md-12' id="causesHeader" style={{fontSize: 50, fontWeight: "bold", paddingTop:100}}>Causes I Care About &nbsp; <FontAwesomeIcon icon={faHandHoldingHeart} style={{color:"maroon"}} /></div>
            <div className='col-md-12'>
                <div className="row" id="causesRow">
                    <div className="col-sm-4" id="causesEd">
                        <h2 id="ED" align="center">Education & Employability</h2>
                        <p>I mentor young people interested in STEM careers through charities like <a href="https://www.stem.org.uk/">STEM Learning UK</a> & <a href="https://volunteeringmatters.org.uk/">Volunteering 
                            Matters</a>, having also worked with <a href="https://stemettes.org/">Stemettes</a> & <a href="https://codefirstgirls.com/">Code First: Girls</a> in the past. I have also been a mentor on <a href="https://careers.db.com/life/advance">Advance - Deutsche Bank's 
                            employability & social mobility programme</a> and support their many STEM, Employability & Literacy focused CSR initiatives through <a href="https://country.db.com/uk/responsibility/index">Born To Be</a> - DB's Youth Engagement Programme.
                            I often visit my alma mater to share knowledge with students through panels & lectures and am an alumnus-mentor 
                            for <a href="https://www.duwib.org.uk/about/">Durham University Women in Business</a> & <a href="https://www.linkedin.com/company/durham-university-women-in-stem/?originalSubdomain=uk">Durham Women in STEM</a>.</p>
                    </div>
         
                <div className="col-sm-4" id="causesMH"> 
                <h2 id="MH" align="center">Mental Health</h2>                   
                    <p>My own struggle with burnout a few years ago and the realisation that mental health issues are far more common than we think, I trained as a Mental Health First Aider with <a href="https://mhfaengland.org/">MHFA England</a> and volunteer as the first 
                        port of call for anyone seeking help & regularly drive initiatives to destigmatize the conversation around mental health at Deutsche Bank, especially around the topic of burnout.</p>
                </div>
                <div className="col-sm-4" id="causesDI">
                    <h2 id="DI" align="center">Diversity & Inclusion</h2>
                    <p>I am a <a href="https://rmrkblty.org/iamremarkable">#IAmRemarkable</a> <a href="https://drive.google.com/file/d/1RJi3QjMWg5uKe1vWayT7MqkyUVVIVu1h/view">facilitator & host workshops</a>
                        to discuss challenges of underrepresented groups in the workplace & help develop their
                        self-promotion skills. I've conducted workshops with over 275 people so far.</p>

                    <p>I am a huge proponent of highlighting accessible role models from all backgrounds to encourage talent. I was
                        featured in the <a href="https://www.1mwis.com/profiles/Souyma-Singh">Million STEM</a> campaign to highlight a million role models! I
                        also contributed to <a href="https://sway.office.com/QAD1kCwBA6Ca1Smg?loc=swsp">Durham University's Journeys Project</a> - a series that highlights career journeys of students and alumni from 
                        diverse backgrounds.</p>
                </div>
            </div>
        </div>
        </div>
        </div>
        </React.Fragment>

    );
}

export default Causes;
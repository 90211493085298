import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import './css/index.css';
import {Cursor} from './js/App';
import RenderPage from './js/Pages';
import reportWebVitals from './reportWebVitals';


/*
The main sections of index defined in App.js are called here to be rendered in apt divs
*/

ReactDOM.render(
  <React.StrictMode>
    <Cursor />
  </React.StrictMode>,
  document.getElementById('hello')
);

ReactDOM.render(
  <React.StrictMode>
    <RenderPage />
  </React.StrictMode>,
  document.getElementById('section')
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

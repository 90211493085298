import '../css/App.css';
import React from 'react';
import SideBar from './assets/sidemenu';
import olympic from './assets/img/olympic.jpg';
import About from '../js/pages/intro/about';
import Social from '../js/pages/intro/social';
import HelloCursor from '../js/pages/intro/hellocursor';

/*
* Stuff other than sections like intro and SM links defined here
*/

function Cursor(){

  return(
    <React.Fragment>

      <div className='sideMenu' align='center'>
        <blockquote align="left" style={{margin: 10, color: '#6E13A5'}}>
          <q><b>If adventures will not befall a young lady in her own village, she must seek them abroad.</b></q>
          <br></br>
          <cite>- Jane Austen, Northanger Abbey</cite>
        </blockquote>
        <SideBar pageWrapId={"page-wrap"} outerContainerId={"App"}></SideBar>
      </div>
      
      <div className="row" style={{paddingTop: 50, paddingBottom: 50, paddingLeft: 10, paddingRight: 10}}>
        <HelloCursor />

        <div className="col-sm-6">
          <img src={olympic} className="circular-image" alt=""/>
        </div>

        <div className="col-sm-6" id="aboutText">
          <Social />
          <br></br>
          <About />
        </div>
     
      </div> 
    </React.Fragment>
    );
  
}

export {Cursor} ;

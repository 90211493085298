import React from 'react';
import '../../css/App.css';

function MenuBar() {
    return (
        <div className="menubar">
            <p>
                <a href="#languages">LANGUAGES</a> | <a href="#hobbies">HOBBIES</a> | <a href="#causes">CAUSES</a> | <a href="#podcast">PODCASTS</a> | <a href="#article">WRITINGS</a> | <a href="#video">VIDEOS</a>
            </p>
        </div>
    );
}

export default MenuBar;